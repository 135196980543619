<template>
  <div class="orders container">
	<div class="row">
		<div class="col-4">
			<p class="h6 text-left text-primary"><b>{{ pageTitle }}</b></p> 
		</div>
		<div class="col-8 text-right">
			<CButton v-if="opType == 'Entry' && locationType != 'online'" color="dark" class="mr-3" 
									v-on:click.prevent="openNewOrderEntry()">New Order</CButton>
			<!-- <CButton v-if="opType == 'Entry' && !locationType.startsWith('retail') && locationType != 'online'"  color="dark" 
									v-on:click.prevent="openOutstandingAmts()">Outstanding Amounts</CButton> -->
		</div>
	</div>
   
	<p class="text-danger" align="left">{{alert}}</p>

    <!--------------------------------- Modal: Order Details -------------------------------------------->

	<CModal title="Order Details" color="info" :show.sync="orderModal" size="lg">	
		<div slot="header">
			<div class="form-group row">
				<CButton v-if="opType != 'Dispatch'" color="dark" class="ml-2 mr-2" v-on:click.prevent="openOrderDetailsModify()">Modify Order</CButton>
				<!-- <CButton v-if="opType != 'Dispatch' && !locationType.startsWith('retail')" color="dark" class="ml-2 mr-2" v-on:click.prevent="openOrderDetailsModify()">Modify Order</CButton> -->
				<CButton color="dark" class="ml-2 mr-2" v-on:click.prevent="orderModal=false">Close</CButton>
				<CButton v-if="locationType.startsWith('retail')" color="dark" class="ml-2 mr-2" v-on:click.prevent="serverCall('saveRetailOrder')">Save</CButton>
			</div>
			<div>
				<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
				<scale-loader :loading="loading"></scale-loader>
			</div>
		</div>	

		<div slot="footer">
		</div>

		<div class="form-group row">
			<label class="col-sm-6 font-weight-bold text-left">Order Nbr: {{order.orderNbrShort}}</label>
			<label v-if="order.altNbr!=''" class="col-sm-6 font-weight-bold text-right">Alternate Nbr: {{ order.altNbr }}</label>
		</div>


		<div class="form-check form-check-inline">
			<input v-model="orderDetailsTab" class="form-check-input" type="radio" id="orderDetailsTab" value=1 />
			<label class="form-check-label" for="orderDetailsTab">Details</label>
		</div>
		<div v-if="locationType=='online'" class="form-check form-check-inline">
			<input v-model="orderDetailsTab" class="form-check-input" type="radio" id="orderDetailsTab" value=2 />
			<label class="form-check-label" for="orderDetailsTab">Messages</label>
		</div>
		
		<hr />

		<div v-if="orderDetailsTab==1">

			<div v-if="!locationType.startsWith('retail')">
				<div class="row">
					<label class="col-sm-12 text-left">
						Total={{ order.total }}, Paid So Far={{ order.paidSoFar }}, Outstanding: {{ order.outstanding }}
					</label>
				</div>

				<div v-for="payment in order.payments" :key="payment.date">
					<div class="row">
						<label class="col-sm-12 text-left">
							{{ new Date(payment.date).toLocaleDateString() }}, 
							{{ payment.type }}, 
							{{ payment.amt }}
						</label>
					</div>
				</div>
			</div>
			<div v-else>
				<div class="row">
					<label class="col-sm-3 col-form-label font-weight-bold text-right">Sale Date</label>
						<div class="col-sm-3 text-left">
						<datepicker v-model="order.invoiceDate" name="currentDate" :disabled-dates="disabledDates"></datepicker>
					</div>
					<label class="col-sm-3 col-form-label font-weight-bold text-right">Alternate Nbr</label>
					<input type="text" class="col-sm-2 form-control text-left" v-model="order.altNbr">

				</div>
				<div class="row">
					<label class="col-sm-3 font-weight-bold text-right">Ticket Nbr</label>
					<input type="text" class="col-sm-2 form-control text-left" v-model="order.serviceTicket">
				</div>
				<div class="row">
					<label class="col-sm-3 font-weight-bold text-right">Order Total</label>
					<label class="col-sm-3 text-left">{{order.total}}</label>
					<label class="col-sm-3 font-weight-bold text-right">Paid</label>
					<label class="col-sm-3 text-left">{{order.paidSoFar}}</label>
				</div>
				<div class="row">
					<label class="col-sm-3 font-weight-bold text-right">Cash Paid</label>
					<label class="col-sm-3 text-left">{{order.paidCash}}</label>
					<label class="col-sm-3 font-weight-bold text-right">Bank Paid</label>
					<label class="col-sm-3 text-left">{{order.paidBank}}</label>
				</div>
				<div class="row">
					<label class="col-sm-3 font-weight-bold text-right">Discount</label>
					<label class="col-sm-3 text-left">{{order.discount}}</label>
					<label class="col-sm-3 font-weight-bold text-right">Surcharge</label>
					<label class="col-sm-3 text-left">{{order.surcharge}}</label>
				</div>
				<div class="row">
					<label class="col-sm-3 font-weight-bold text-right">Note</label>
					<textarea class="col-sm-7 form-control" v-model="order.note"></textarea>
				</div>
			</div>

			<br> 

			<div v-if="!locationType.startsWith('retail')">
				<p class="font-weight-bold" align="center">{{partnerType}}</p>
				<form>
					<div class="form-group row">
					<label class="col-sm-2 col-form-label font-weight-bold text-right">Name</label>
					<input disabled type="text" class="col-sm-8 form-control" v-model="order.partner.name">
					</div>

					<div class="form-group row">
						<label class="col-sm-2 col-form-label font-weight-bold text-right">Phone</label>
						<input disabled type="text" class="col-sm-3 form-control" v-model="order.partner.phone">

						<label class="col-sm-2 col-form-label font-weight-bold text-right">State</label>
						<input disabled type="text" class="col-sm-3 form-control" v-model="order.partner.state">
					</div>
					<div class="form-group row">
						<label class="col-sm-2 col-form-label font-weight-bold text-right mr-3">WA URL</label>
						<a v-bind:href="waURL" target="_blank">{{ waURL }} </a>
					</div>
				</form>
			</div>
			<br>

			<p class="font-weight-bold" align="center">Order Details</p>
			<vue-good-table ref="orderTable" :columns="colItems" :rows="rowItems" 
				styleClass="vgt-table bordered condensed"
				:lineNumbers="true"
				:sort-options="{enabled: false}"
			> 
			</vue-good-table>

		</div>

		<div v-if="orderDetailsTab==2">
            <div class="form-group row">
			  <label class="col-sm-6 font-weight-bold text-left">User: {{ order.partner.name }}</label>
              <label class="col-sm-6 font-weight-bold text-right">Phone: {{ order.partner.phone }}</label>
              <!-- <a v-bind:href="waURL" class="link-dark ml-3" target="_blank">{{ waURL }} </a> -->
            </div>

            <OrderMessages ref="orderMessagesRef"
              :orgIdProp=this.orgId
              :orderProp="order"
			  :userIdProp=null
            />
		</div>


	</CModal>

    <!--------------------------------- Modal: Order Invoice Print -------------------------------------------->

	<div>
		<CModal title="Order Invoices" color="info" :show.sync="invoicesModal" size="lg">	
			<div slot="header">
				<div class="form-group row">
					<CButton color="dark" class="ml-2 mr-2" v-on:click.prevent="printReceipts()">Print/Download</CButton>
					<CButton color="dark" class="ml-2 mr-2" v-on:click.prevent="invoicesModal=false">Close</CButton>
				</div>
			</div>	

            <div id="receipt">
				<div v-if="currentPrintType=='Invoice'"> 
					<div v-for="receiptHTML in receiptHTMLs" style="page-break-after: always"> 
						<span v-html="receiptHTML"></span>
					</div>
				</div>
				<div v-else>
					<div v-for="receiptHTML in receiptHTMLs"> 
						<span v-html="receiptHTML"></span>
					</div>
				</div>
            </div>

			<div slot="footer">
			</div>

 		</CModal>
	</div>

	<!--------------------------------- Modal - Payment Summary -------------------------------------------->

	<div>
		<CModal title="Payment Summary" color="info" :show.sync="paymentSummaryModal" size="sm">	
			<div slot="header">
				<h5>Payment Summary</h5>
			</div>	

			<div slot="footer">
				<CButton color="dark" class="ml-2 mr-2" v-on:click.prevent="paymentSummaryModal=false">Close</CButton>
			</div>

			<table class="table">
				<thead>
					<tr>
						<th scope="col">#</th>
						<th scope="col">Category</th>
						<th class="text-right" scope="col">Amount</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th scope="row">1</th>
						<td>Total</td>
						<td class="text-right">{{paymentSummary['total'].toLocaleString('en-IN')}}</td>
					</tr>
					<tr>
						<th scope="row">2</th>
						<td>Paid So Far</td>
						<td class="text-right">{{paymentSummary['paid'].toLocaleString('en-IN')}}</td>
					</tr>
					<tr>
						<th scope="row">3</th>
						<td>Outstanding</td>
						<td class="text-right">{{paymentSummary['outstanding'].toLocaleString('en-IN')}}</td>
					</tr>
					<tr>
						<th scope="row">4</th>
						<td>Paid in Cash</td>
						<td class="text-right">{{paymentSummary['totalPaidCash'].toLocaleString('en-IN')}}</td>
					</tr>
					<tr>
						<th scope="row">5</th>
						<td>Paid in Bank</td>
						<td class="text-right">{{paymentSummary['totalPaidBank'].toLocaleString('en-IN')}}</td>
					</tr>
					<tr>
						<th scope="row">6</th>
						<td>Tax</td>
						<td class="text-right">{{paymentSummary['tax'].toLocaleString('en-IN')}}</td>
					</tr>
				</tbody>
			</table>

		</CModal>
	</div>

	<!--------------------------------- Modal - Outstanding Summary -------------------------------------------->

	<div>
		<CModal title="Outstanding Summary" color="info" :show.sync="outstandingSummaryModal" size="lg">	
			<div slot="header">
				<h5>Outstanding List</h5>
			</div>	

			<div slot="footer">
				<CButton color="dark" class="ml-2 mr-2" v-on:click.prevent="outstandingSummaryModal=false">Close</CButton>
			</div>

			<table class="table">
				<thead>
					<tr>
						<th scope="col">#</th>
						<th scope="col">Customer</th>
						<th class="text-right" scope="col">Amount</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for='(outstanding, index) in outstandingList'>
						<th scope="row">{{outstanding.nbr}}</th>
						<td>{{outstanding.name}}</td>
						<td class="text-right">{{outstanding.amt.toLocaleString('en-IN')}}</td>
					</tr>
				</tbody>
			</table>

		</CModal>
	</div>


	<!--------------------------------- Modal -Item Summary -------------------------------------------->

	<div>
		<CModal title="Item Summary" color="info" :show.sync="itemSummaryModal" size="lg">	
			<div slot="header">
				<h5>Item Summary</h5>
			</div>	

			<div slot="footer">
				<CButton color="dark" class="ml-2 mr-2" v-on:click.prevent="itemSummaryModal=false">Close</CButton>
			</div>

			<div align="center">
				<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
				<scale-loader :loading="loading"></scale-loader>
			</div>

			<div class="row btn-group">
				<div class="col-sm-6 text-left">
					<CButton color="dark" class="mr-2" v-on:click.prevent="$refs.itemSummaryTable.expandAll()">Expand All</CButton>
					<CButton color="dark" v-on:click.prevent="$refs.itemSummaryTable.collapseAll()">Collapse All</CButton>
				</div>
				<div class="col-sm-6 text-right">
					<CButton v-if="fromdate.getTime()==todate.getTime()" class="mr-2" color="dark" :disabled="disabled"
						v-on:click.prevent="serverCall('transferItemSummary')">Transfer</CButton>
					<CButton v-if="itemSummaryRows.length>0" color="dark"
						v-on:click.prevent="downloadItemSummaryReport()">Download Report</CButton>
				</div>
			</div>
			<br>
			<vue-good-table ref="itemSummaryTable" :columns="itemSummaryCols" :rows="itemSummaryRows" 
				:group-options="{enabled: true,collapsable: true}"
				styleClass="vgt-table bordered condensed"
				:row-style-class="rowStyleClassFn"
				:sort-options="{enabled: false}"
			> 
			</vue-good-table>

		</CModal>
	</div>

		<!-- <div v-if="itemSummaryRows.length>0">
			<div class="row btn-group">
				<div class="col-sm-6 text-left">
					<CButton color="dark" class="mr-2" v-on:click.prevent="$refs.itemSummaryTable.expandAll()">Expand All</CButton>
					<CButton color="dark" v-on:click.prevent="$refs.itemSummaryTable.collapseAll()">Collapse All</CButton>
				</div>
				<div class="col-sm-6 text-right">
					<CButton v-if="itemSummaryRows.length>0" color="dark"
						v-on:click.prevent="downloadItemSummaryReport()">Item Summary Report</CButton>
				</div>
			</div>
			<br>
			<div class="card">
				<div class="card-body">
					<h5 class="card-title text-center">Item Summary</h5>
					<hr>
					<vue-good-table ref="itemSummaryTable" :columns="itemSummaryCols" :rows="itemSummaryRows" 
						:group-options="{enabled: true,collapsable: true}"
						styleClass="vgt-table bordered condensed"
						:row-style-class="rowStyleClassFn"
						:sort-options="{enabled: false}"
					> 
					</vue-good-table>
				</div>
			</div>
		</div> -->


    <!--------------------------------- Main Page: Order List : Search Criteria -------------------------------------------->

	<form class="form">
		<CCard :color="'secondary'">
			<CCardBody>
				<div class="row">
					<label class="font-weight-bold ml-3 mr-2" for="fromdate">From:</label>
					<datepicker :disabled-dates="disabledDates" v-model="fromdate" name="fromdate"></datepicker>

					<label class="font-weight-bold ml-3 mr-2" for="todate">To:</label>
					<datepicker :disabled-dates="disabledDates" v-model="todate" name="todate"></datepicker>
					
					<!-- <label class="font-weight-bold mr-2">Date Range Type:</label> -->
					<label class="radio-inline ml-3">
						<input type="radio" id="dateRangeType" value="invoiceDate" v-model="dateRangeType"> Sale/Invoice Date
					</label>
					<label class="radio-inline ml-3">
						<input type="radio" id="dateRangeType" value="createDate" v-model="dateRangeType"> Create Date
					</label>
				</div>

				<div class="row">
					<div class="col-sm-3 text-left">
						<label class="col-form-label font-weight-bold text-left">Invoice?</label>
						<select class="form-control" v-model="selInvoiceIssued">
							<option selected="selected">NA</option>
							<option>Yes</option>
							<option>No</option>
						</select>
					</div>

					<div class="col-sm-3 text-left">					
						<label class="col-form-label font-weight-bold text-left">GSTN?</label>
						<select class="form-control" v-model="selGSTN">
							<option selected="selected">NA</option>
							<option>Yes</option>
							<option>No</option>
						</select>
					</div>

					<div class="col-sm-3 text-left">
						<label class="col-form-label font-weight-bold text-left" for="todate">Order Nbr</label>
						<input type="text" class="form-control text-left" v-model="orderNbrShort">
					</div>

					<div class="col-sm-3 text-left">
						<label class="col-form-label font-weight-bold text-left" for="todate">Alternate Order Nbr</label>
						<input type="text" class="form-control text-left" v-model="altNbr">
					</div>
				</div>

			</CCardBody>
		</CCard>

		<div class="row">
			<div class="col-12 text-center">
				<CButton color="dark" class="ml-4" v-on:click.prevent="serverCall('getOrders')">Get All Orders</CButton>
				<CButton v-if="locationType=='retail-service'" color="dark" class="ml-4" 
								v-on:click.prevent="serverCall('getServiceOrders')">Get Service Orders</CButton>
			</div>
		</div>

	</form>	

	<br>

 <!--------------------------------- Main Page: Order List -------------------------------------------->

	<div>
		<vue-good-table
			ref="reportTable"
			:columns="colOrders"
			:rows="rowOrders"
			:line-numbers="true"
			:pagination-options="pagination_options" 
			:sort-options="{
				enabled: false,
			}"
			styleClass="vgt-table bordered condensed"
			@on-cell-click="orderSelected" 
			@on-selected-rows-change="selectionChanged"
			:selectOptions="{
								enabled: true,
								selectOnCheckboxOnly: false, 
							}"
			>

			<div v-if="opType=='Approval'" slot="selected-row-actions">
				<CButton color="dark" class="mr-3"
					v-on:click.prevent="serverCall('updateOrderStatus', 'DeliveryReady')">Mark Approved</CButton>
				<CButton color="dark" 
					v-on:click.prevent="serverCall('updateOrderStatus', 'cancelled')">Cancelled</CButton>
			</div>
			<div v-else-if="opType=='Dispatch'" slot="selected-row-actions">
				<CButton color="dark" class="mr-3" v-on:click.prevent="serverCall('getDeliveryReceipts', 'PackingList')">Packing List</CButton>
				<CButton color="dark" class="mr-3" v-on:click.prevent="serverCall('getDeliveryReceipts', 'Invoice')">Invoices</CButton>
				<CButton color="dark" class="mr-3"
					v-on:click.prevent="serverCall('updateOrderStatus', 'Delivered')">Mark Dispatched</CButton>
				<CButton color="dark"  
					v-on:click.prevent="serverCall('updateOrderStatus', 'PreDelivery')">Reject Orders</CButton>
			</div>
			<div v-else slot="selected-row-actions">
				<CButton v-if="!locationType.startsWith('retail')" color="dark" class="mr-3"
					v-on:click.prevent="serverCall('updateOrderStatus', 'Paid')">Paid Fully</CButton>
				<CButton v-if="!locationType.startsWith('retail')" color="dark" class="mr-3"
					v-on:click.prevent="serverCall('updateOrderStatus', 'Delivered')">Delivered</CButton>
				<CButton color="dark" 
					v-on:click.prevent="serverCall('updateOrderStatus', 'cancelled')">Cancelled</CButton>
			</div>

			<template slot="table-row" slot-scope="props">
				<span v-if="props.column.field == 'orderNbrShort'">
					<span><a href="#">{{ props.row.orderNbrShort }}</a></span> 
				</span>
			</template>
			<template slot="table-column" slot-scope="props">
				<span v-if="props.column.label =='Name' || props.column.label =='Phone'">
					{{partnerType}}  {{props.column.label}}
				</span>
				<span v-else>
					{{props.column.label}}
				</span>
			</template>

	  	</vue-good-table>

		<br>
		<div align="center">
			<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
			<scale-loader :loading="loading"></scale-loader>
		</div>
		<br>
		<div class="row">
			<div class="col-12 text-center">
				<CButton v-if="rowOrders.length>0" color="dark"  
								v-on:click.prevent="getPaymentSummary()">Payment Summary</CButton>
				<CButton v-if="rowOrders.length>0 && 
								(locationType.startsWith('retail') || locationType.startsWith('standard'))" color="dark" class="ml-4" 
								v-on:click.prevent="serverCall('getItemSummary')">Item Summary</CButton>
				<CButton v-if="rowOrders.length>0" color="dark" class="ml-4" 
								v-on:click.prevent="serverCall('getOrdersExcel')">Order Report</CButton>
				<CButton v-if="rowOrders.length>0" color="dark" class="ml-4" 
								v-on:click.prevent="getOutstandingSummary()">Outstanding List</CButton>
			</div>
		</div>
		<br>

	</div>

	<main id="main">
	</main>

  </div>
</template>

<script>
import axios from "axios"
import Datepicker from 'vuejs-datepicker';
import ScaleLoader from "@/views/utils/ScaleLoader";
import { firebaseAuth } from '../../firebaseConfig.js';
var FileSaver = require('file-saver')
import Helper from "../utils/Helper.vue"
import OrderMessages from './OrderMessages.vue'

export default {
	name: 'orders',

	mixins: [
		Helper
	],

    components: {
		Datepicker,
        ScaleLoader,
		OrderMessages,
	},
		
	data () {

		return {
			orgId: null,

			disabled: false,
      		timeout: null,

			orderDetailsTab: 1,

			pageTitle: '',
			alert: '',

			opType: this.$route.params.opType,
			location: this.$route.params.location,
			locationType: this.$route.params.locationType,
			orderType: '',
			orderSubType: 'All',
			partnerType: 'Customer',

			fromdate: null,
			todate: null,
			disabledDates: {
				//to: new Date(2016, 0, 5), // Disable all dates up to specific date
				from: new Date(), // Disable all dates after specific date
			},
			orderNbrShort: '',
			selInvoiceIssued: 'NA',
			selGSTN: 'NA',
			altNbr: '',
			dateRangeType: 'invoiceDate',

            pagination_options: {
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'top',
                perPageDropdown: [10, 20, 30, 40],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
			},
			
			loading: false,

			colOrders: [],
			colOrdersStandard: [
				{
					label: 'Order Number',
					field: 'orderNbrShort',
					html: true,
					filterOptions: {'enabled': true}
				},
				{
					label: 'Alternate Order Number',
					field: 'altNbr',
					filterOptions: {'enabled': true},
					sortable: true,
				},
				{
					label: 'Name',
					field: 'partner.name',
					filterOptions: {'enabled': true}
				},
				{
					label: 'Phone',
					field: 'partner.phone',
					filterOptions: {'enabled': true}
				},
				{
					label: 'Order Total',
					field: 'total',
					type: 'decimal',
					sortable: true,
					filterOptions: {'enabled': true}
				},
				// {
				// 	label: 'Paid So Far',
				// 	field: 'paidSoFar',
				// 	type: 'decimal',
				// 	filterOptions: {'enabled': true}
				// },
				{
					label: 'Cash Paid',
					field: 'paidCash',
					type: 'decimal',
					filterOptions: {'enabled': true}
				},
				{
					label: 'Bank Paid',
					field: 'paidBank',
					type: 'decimal',
					filterOptions: {'enabled': true}
				},
				{
					label: 'Invoice Date',
					field: 'invoice_date',
					type: 'date',
					dateInputFormat: 'yyyy-MM-dd', // expects 2018-03-16
    				dateOutputFormat: 'd MMM yy', // outputs Mar 16th 2018
					sortable: true,
				},
				{
					label: 'Create Date',
					field: 'create_date',
					type: 'date',
					dateInputFormat: 'yyyy-MM-dd', // expects 2018-03-16
    				dateOutputFormat: 'd MMM yy', // outputs Mar 16th 2018
					sortable: true,
				},
				{
					label: 'Payment Date',
					field: 'payment_date',
					type: 'date',
					dateInputFormat: 'yyyy-MM-dd', // expects 2018-03-16
    				dateOutputFormat: 'd MMM yy', // outputs Mar 16th 2018
					sortable: true,
				},
				{
					label: 'Delivery Date',
					field: 'delivery_date',
					type: 'date',
					dateInputFormat: 'yyyy-MM-dd', // expects 2018-03-16
    				dateOutputFormat: 'd MMM yy', // outputs Mar 16th 2018
					sortable: true,
				},
				{
					label: 'Cancel Date',
					field: 'cancelled_date',
					type: 'date',
					dateInputFormat: 'yyyy-MM-dd', // expects 2018-03-16
    				dateOutputFormat: 'd MMM yy', // outputs Mar 16th 2018
					sortable: true,
				},
			],

			colOrdersRetail: [
				{
					label: 'Order Number',
					field: 'orderNbrShort',
					html: true,
					filterOptions: {'enabled': true}
				},
				{
					label: 'Alternate Order Number',
					field: 'altNbr',
					filterOptions: {'enabled': true}
				},
				{
					label: 'Order Total',
					field: 'total',
					type: 'decimal',
					sortable: true,
					filterOptions: {'enabled': true}
				},
				// {
				// 	label: 'Paid',
				// 	field: 'paidSoFar',
				// 	type: 'decimal',
				// 	filterOptions: {'enabled': true}
				// },
				{
					label: 'Cash Paid',
					field: 'paidCash',
					type: 'decimal',
					filterOptions: {'enabled': true}
				},
				{
					label: 'Bank Paid',
					field: 'paidBank',
					type: 'decimal',
					filterOptions: {'enabled': true}
				},
				{
					label: 'Sale Date',
					field: 'invoice_date',
					type: 'date',
					dateInputFormat: 'yyyy-MM-dd', // expects 2018-03-16
    				dateOutputFormat: 'd MMM yy', // outputs Mar 16th 2018
					sortable: true,
				},
				{
					label: 'Create Date',
					field: 'create_date',
					type: 'date',
					dateInputFormat: 'yyyy-MM-dd', // expects 2018-03-16
    				dateOutputFormat: 'd MMM yy', // outputs Mar 16th 2018
					sortable: true,
				},
				{
					label: 'Cancel Date',
					field: 'cancelled_date',
					type: 'date',
					dateInputFormat: 'yyyy-MM-dd', // expects 2018-03-16
    				dateOutputFormat: 'd MMM yy', // outputs Mar 16th 2018
					sortable: true,
				},
			],

			colOrdersOnline: [
				{
					label: 'Order Number',
					field: 'orderNbrShort',
					html: true,
					filterOptions: {'enabled': true}
				},
				// {
				// 	label: 'User ID',
				// 	field: 'userId',
				// 	filterOptions: {'enabled': false}
				// },
				// {
				// 	label: 'Alternate Order Number',
				// 	field: 'altNbr',
				// 	filterOptions: {'enabled': true}
				// },
				{
					label: 'Order Total',
					field: 'total',
					type: 'decimal',
					sortable: true,
					filterOptions: {'enabled': true}
				},
				// {
				// 	label: 'Paid',
				// 	field: 'paidSoFar',
				// 	type: 'decimal',
				// 	filterOptions: {'enabled': true}
				// },
				{
					label: 'Cash Paid',
					field: 'paidCash',
					type: 'decimal',
					filterOptions: {'enabled': true}
				},
				{
					label: 'Bank Paid',
					field: 'paidBank',
					type: 'decimal',
					filterOptions: {'enabled': true}
				},
				{
					label: 'Sale Date',
					field: 'invoice_date',
					type: 'date',
					dateInputFormat: 'yyyy-MM-dd', // expects 2018-03-16
    				dateOutputFormat: 'd MMM yy', // outputs Mar 16th 2018
					sortable: true,
				},
				{
					label: 'Create Date',
					field: 'create_date',
					type: 'date',
					dateInputFormat: 'yyyy-MM-dd', // expects 2018-03-16
    				dateOutputFormat: 'd MMM yy', // outputs Mar 16th 2018
					sortable: true,
				},
				{
					label: 'Cancel Date',
					field: 'cancelled_date',
					type: 'date',
					dateInputFormat: 'yyyy-MM-dd', // expects 2018-03-16
    				dateOutputFormat: 'd MMM yy', // outputs Mar 16th 2018
					sortable: true,
				},
			],

			colServiceOrders: [
				{
					label: 'Ticket Number',
					field: 'serviceTicket',
					filterOptions: {'enabled': true}
				},
				{
					label: 'Service Type',
					field: 'item2',
					sortable: true,
					filterOptions: {'enabled': true}
				},
				{
					label: 'Note',
					field: 'note',
					filterOptions: {'enabled': true}
				},
				{
					label: 'Order Total',
					field: 'total',
					type: 'decimal',
				},
				// {
				// 	label: 'Paid',
				// 	field: 'paidSoFar',
				// },
				{
					label: 'Cash Paid',
					field: 'paidCash',
					type: 'decimal',
				},
				{
					label: 'Bank Paid',
					field: 'paidBank',
					type: 'decimal',
				},
				{
					label: 'Sale Date',
					field: 'invoice_date',
					type: 'date',
					dateInputFormat: 'yyyy-MM-dd', // expects 2018-03-16
    				dateOutputFormat: 'd MMM yy', // outputs Mar 16th 2018
					sortable: true,
				},
				{
					label: 'Create Date',
					field: 'create_date',
					type: 'date',
					dateInputFormat: 'yyyy-MM-dd', // expects 2018-03-16
    				dateOutputFormat: 'd MMM yy', // outputs Mar 16th 2018
					sortable: true,
				},
				{
					label: 'Cancel Date',
					field: 'cancelled_date',
					type: 'date',
					dateInputFormat: 'yyyy-MM-dd', // expects 2018-03-16
    				dateOutputFormat: 'd MMM yy', // outputs Mar 16th 2018
					sortable: true,
				},
			],

        	rowOrders: [],

			reportName: this.$route.params.location + " Orders" ,
			reportLongName: "",
			reportData: "",

			itemSummaryReport: '',
			itemSummaryReportName: '',

			paymentSummary: {'total': 0, 'paid': 0, 'outstanding': 0, 'tax': 0, 'totalPaidCash': 0, 'totalPaidBank':0},

			order: {'nbr': '', 'type': 'Sale', 'location': this.location,
                        'dateStr': '', 'status': 0, 'total': 0, 'allGstOrder': 'Yes', 'note': '',
                        'items': [], 
                        'partner':{'name': '', 'phone': '', 'email': '', 'address': ''},
                        'payInstructions': '', 'paid': 'No', 'paymentDateStr': null, 'paidAmt': 0,
                        'delivered': 'Yes', 'deliveryDateStr': this.currentDate, 
						'cancelled': 'No', 'cancelledDateStr': null
                       },

			curRowSelected: null,
			selectedRows: [],

			waURL: '',

			colItems: [
				{
					label: 'Item1',
					field: 'item1Name',
					thClass: 'text-center',
				},
				{
					label: 'Item2',
					field: 'name',
					thClass: 'text-center',
				},
				{
					label: 'Unit Price',
					field: 'price',
					thClass: 'text-center',
					type: 'decimal',
				},
				{
					label: 'Unit Tax',
					field: 'tax',
					thClass: 'text-center',
					tdClass: 'text-right',
					type: 'decimal'
				},
				{
					label: 'Unit Discount',
					field: 'discount',
					thClass: 'text-center',
					type: 'decimal',
				},
				{
					label: 'Unit Sale Price',
					field: 'salePrice',
					thClass: 'text-center',
					type: 'decimal',
				},            
				{
					label: 'Quantity',
					field: 'qty',
					thClass: 'text-center',
					type: 'number',
				},
				{
					label: 'Total',
					field: 'total',
					thClass: 'text-center',
					type: 'decimal',
				},
			],

			rowItems: [],

			itemSummaryCols: [
				{
					label: 'Item1',
					field: 'item1',
					thClass: 'text-center',
				},
				{
					label: 'Item2',
					field: 'item2',
					thClass: 'text-center',
				},
				{
					label: 'Sale Quantity',
					field: 'qty',
					thClass: 'text-center',
					type: 'number',
				},
				{
					label: 'Inventory-Out',
					field: 'outCnt',
					thClass: 'text-center',
					type: 'number',
				},
				// {
				// 	label: 'Sale Amount',
				// 	field: 'amt',
				// 	thClass: 'text-center',
				// 	type: 'decimal',
				// },
			],
			itemSummaryRows: [],

			orderModal: false,
			invoicesModal: false,
			receiptHTMLs: [],
			currentPrintType: '',

			paymentSummaryModal: false,
			itemSummaryModal: false,

			outstandingSummary: {'total': 0, 'paid': 0, 'outstanding': 0},
			outstandingList: [],
			outstandingSummaryModal: false,

		};
	},

	computed: {
	},

	mounted(){
		this.orgId = this.$route.params['orgId']

		this.fromdate = new Date()
		this.todate = new Date()

		this.opType = this.$route.params['opType']
		this.location = this.$route.params['location']

		this.locationType = this.$route.params['locationType']
		if (!this.locationType)
			this.locationType = 'standard'
		if (this.locationType == 'online')
			this.dateRangeType = 'createDate'
		else
			this.dateRangeType = 'invoiceDate'

	    this.orderType = this.$route.params['orderType']
		if (this.orderType == 'Sale')
			this.partnerType = 'Customer'
		else 
			this.partnerType = 'Supplier'
		if (this.location=='All')
			this.pageTitle = this.orderType + " Orders" 
		else
			this.pageTitle = this.location + " " + this.orderType + " Orders"
		if (this.opType=='Approval' || this.opType=="Dispatch")
			this.pageTitle = this.pageTitle + " - For " + this.opType

		// if Order List is called from Order Entry, it will receive 'originalSearchCriteria'
		var originalSearchCriteria = this.$route.params['originalSearchCriteria']
		if (originalSearchCriteria != null){
			this.fromdate = originalSearchCriteria['fromDate']
			this.todate = originalSearchCriteria['toDate']
			this.dateRangeType = originalSearchCriteria['dateRangeType']
			this.orderNbrShort = originalSearchCriteria['orderNbrShort'],
			this.paymentType = originalSearchCriteria['paymentType'],
			this.taxStatus = originalSearchCriteria['taxStatus'],
			this.altNbr = originalSearchCriteria['altNbr'],
			this.serverCall('getOrders')
		}

	},

	beforeDestroy () {
		// clear the timeout before the component is destroyed
		clearTimeout(this.timeout)
  	},

	methods: {
		rowStyleClassFn(row) {
			if (row.diff == 1){
				return 'yellow'
			}
		},

		// Thu, 10 Mar 2022 12:00:00 GMT
		formatDate: function(value) {
			if (value)
				return (new Date(value).toLocaleDateString())
		},

		// Current Selected Rows
		selectionChanged(params) {
            this.selectedRows = params.selectedRows;
        },

		// User clicks on 'nbrShort'	
		orderSelected(params){	
			// Current selected Row
			if (this.curRowSelected != null){
				// If new row is not same as previous row, uncheck previous row
				if (this.curRowSelected.originalIndex!=params.row.originalIndex)
					this.curRowSelected.vgtSelected = false
			}
			// New selected row
			this.curRowSelected = params.row

			if (params.column.field=="orderNbrShort"){
				this.showOrderDetailsView()
			}
		},

		// Show Order Details Modal
		showOrderDetailsView(){
            var self = this

            // Check if User is selected
			if (self.curRowSelected==null){
				self.alert = "Order not selected."
				return
			}

			// Initialize order object
			self.order = self.curRowSelected
			self.rowItems = self.order.items
			let phone = self.order.partner.phone
			if (phone){
				phone = phone.trim()
				if (!phone.startsWith('91') && !phone.startsWith('+'))
					phone = '91' + phone
				self.waURL = "https://wa.me/" + phone
			}
			else {
				self.waURL = ""
			}
			
			// Show modal with order details
			self.orderDetailsTab = 1
			self.orderModal = true
		},

		// Open Order Details Modification
		openOrderDetailsModify(){
            var self = this
			self.alert = null

			var originalSearchCriteria = {'fromDate': self.fromdate,
											'toDate': self.todate,
											'orderNbrShort': self.orderNbrShort,
											'paymentType': self.paymentType,
											'taxStatus': self.taxStatus,
											'altNbr': self.altNbr,
											'dateRangeType': self.dateRangeType,
										}

			self.$router.push({ name: 'Order Entry', 
								params: { 'location': self.location,
										  'orderType': self.orderType,
										  'order': self.order,
										  'originalSearchCriteria': originalSearchCriteria
										} 
							 })
		},

		// Open New Order Entry
		openNewOrderEntry () {
			var self = this
			self.alert = null

			var originalSearchCriteria = {'fromDate': self.fromdate,
											'toDate': self.todate,
											'orderNbrShort': self.orderNbrShort,
											'paymentType': self.paymentType,
											'taxStatus': self.taxStatus,
											'altNbr': self.altNbr,
											'dateRangeType': self.dateRangeType,
										}

			if (self.locationType.startsWith('retail')){
				window.open("https://retail.signledger.com/" + this.$store.state.orgName + "/" + this.location)
			}
			else {
				self.$router.push({
				name: 'Order Entry',
				params: {
							location: self.location,
							orderType: self.orderType,
							order: '',
							'originalSearchCriteria': originalSearchCriteria
						}
				})
			}

		},

		// Open Outstanding Amounts report
		openOutstandingAmts () {
			var self = this
			self.alert = null

			self.$router.push({
				name: 'OutstandingPayments',
				params: {
						location: self.location,
						orderType: self.orderType
				}
			})
		},

        serverCall(name, status) {
            var self = this

			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
						switch(name) {
							case "getOrders":
								self.orderSubType = 'All'
								self.getOrders(idToken)
								break;

							case "getOrdersExcel":
								self.getOrdersExcel(idToken)
								break;

							case "getItemSummary":
								self.getItemSummary(idToken)
								break;

							// Transfer from - Order, to - inventory
							case 'transferItemSummary':
								self.transferItemSummary(idToken)
								break

							case "getServiceOrders":
								self.orderSubType = 'Service'
								self.getServiceOrders(idToken)
								break;
								
							case "saveRetailOrder":
								self.saveRetailOrderToDB(idToken)
								break;
							
							case "updateOrderStatus":
								self.updateOrderStatus(idToken, status)
								break;

							case 'getDeliveryReceipts':
								self.getDeliveryReceipts(idToken, status)
								break

							default:
								console.log("Unknown function: " + name)
						}
					})
				}
				else {
					self.alert = "Please login first."
				}
			})
		},
		

        // Get Orders for the given dates, location
        getOrders(authToken) {
            var self = this
			self.alert = ""
            if (!this.fromdate || !this.todate || !this.location){
                self.alert = "Either fromdate or todate or location not specified."
                return
			}
            if (this.fromdate > this.todate){
                self.alert = "From Date should be less than To Date."
                return
			}
			self.rowOrders = []
			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Orders..."

			var postJson = {
				'opType': this.opType,
				'location': this.location,
				'orderType': this.orderType,
				'dateRangeType': this.dateRangeType,
				'fromDate': this.getMidDay(this.fromdate),
				'toDate': this.getMidDay(this.todate),
				'invoiceIssued': this.selInvoiceIssued,
				'GSTN': this.selGSTN,
				'orderNbrShort': this.orderNbrShort,
				'altNbr': this.altNbr,
			}

			var options = { year: '2-digit', month: 'short', day: 'numeric' }
			var fdStr = this.fromdate.toLocaleDateString("en-US", options)
			var tdStr = this.todate.toLocaleDateString("en-US", options)

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetOrders/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'application/json',
					},
				})
				.then(function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
						console.log("getOrders - error:" + response.data.err)
						// Reset loading message
						self.loading = false
						self.loadingMsg = null
					}
					else {
						self.rowOrders = response.data.dataList
						if (self.locationType.startsWith('retail'))
							self.colOrders = self.colOrdersRetail
						else if (self.locationType.startsWith('online'))
							self.colOrders = self.colOrdersOnline
						else 	
							self.colOrders = self.colOrdersStandard

						// Reset loading message
						self.loading = false
						self.loadingMsg = null
					}
				})
				.catch(function (error) {
					self.alert = String(error)
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
		},

        // Get Excel Report for the given orders
        getOrdersExcel(authToken) {
            var self = this
			self.alert = ""

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Orders Excel Report..."



			var postJson = {
				//'orders': this.rowOrders,
				'orders': self.$refs.reportTable.processedRows[0].children, // Print filtered data
				'orderType': this.orderType,
				'orderSubType': this.orderSubType
			}

			var options = { year: '2-digit', month: 'short', day: 'numeric' }
			var fdStr = this.fromdate.toLocaleDateString("en-US", options)
			var tdStr = this.todate.toLocaleDateString("en-US", options)

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetOrdersExcel/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'application/json',
					},
				})
				.then(function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
						console.log("getOrdersExcel - error:" + response.data.err)
						// Reset loading message
						self.loading = false
						self.loadingMsg = null
					}
					else {
						self.reportData = response.data.reportData
						if (self.orderSubType=='Service')
							self.reportLongName = self.reportName + " - " + "Service - " + fdStr + " To " + tdStr	
						else
							self.reportLongName = self.reportName + " - " + fdStr + " To " + tdStr
						// Reset loading message
						self.loading = false
						self.loadingMsg = null

						if (self.reportData==""){
							self.$alert("No orders data to Report.", '', 'error', { confirmButtonColor: '#161c2d' })
							return
						}
						let blob = new Blob([self.base64toBlob(self.reportData, 
											 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')], {});
						FileSaver.saveAs(blob, self.reportLongName + '.xlsx');
					}
				})
				.catch(function (error) {
					self.alert = String(error)
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
		},

        // Get Item Summary for the given orders
        getItemSummary(authToken) {
            var self = this
			self.alert = ""

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Item Summary..."

			var postJson = {
				'orders': this.rowOrders,
				'orderType': this.orderType,
				'location': this.location,
				'fromDate': this.getMidDay(this.fromdate),
				'toDate': this.getMidDay(this.todate)
			}

			var options = { year: '2-digit', month: 'short', day: 'numeric' }
			var fdStr = this.fromdate.toLocaleDateString("en-US", options)
			var tdStr = this.todate.toLocaleDateString("en-US", options)

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetItemSummary/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'application/json',
					},
				})
				.then(function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
						console.log("getItemSummary - error:" + response.data.err)
						// Reset loading message
						self.loading = false
						self.loadingMsg = null
					}
					else {
						self.itemSummaryRows = response.data.itemSummaryRows
						self.itemSummaryReport = response.data.itemSummaryReport
						self.itemSummaryReportName = self.reportName + " - Item Summary" +  " - " + fdStr + " To " + tdStr
						// Reset loading message
						self.loading = false
						self.loadingMsg = null

						if (self.itemSummaryRows.length<=0)
							self.$alert("No summary data returned", '', 'error', { confirmButtonColor: '#161c2d' })
						else
							self.itemSummaryModal = true
					}
				})
				.catch(function (error) {
					self.alert = String(error)
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
		},

	// Transfer Data. From: Orders, To: Inventory
	transferItemSummary(authToken) {
		var self = this

		// Set loading message
		self.loading = true
		self.loadingMsg = "Transferring data from Orders to Inventory..."

		// To fix issue of double clicking button
		this.disabled = true
		// Re-enable after 5 seconds
		this.timeout = setTimeout(() => {
			this.disabled = false
		}, 5000)

		var postJson = {
			'itemSummaryRows': this.itemSummaryRows,
			'orderType': this.orderType,
			'location': this.location,
			'date': this.getMidDay(this.fromdate),
		}

		axios({
				method: 'POST',
				baseURL: process.env.VUE_APP_ROOT_API,
				url: '/TransferItemSummary/',
				data: postJson,
				headers: {
					'Authorization': 'Bearer ' + authToken,
					'OrgId': self.orgId,
					'Content-Type': 'application/json',
				},
			})
			.then(function (response) {
				if (response.data.err != ''){
					self.alert = response.data.err
					console.log("transferItemSummary - error:" + response.data.err)
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				}
				else {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					self.itemSummaryModal = false
				}
			})
			.catch(function (error) {
				self.alert = String(error)
				// Reset loading message
				self.loading = false
				self.loadingMsg = null
			})
		},


        // Get Service Orders for the given dates, location
        getServiceOrders(authToken) {
            var self = this
			self.alert = ""
            if (!this.fromdate || !this.todate || !this.location){
                self.alert = "Either fromdate or todate or location not specified."
                return
			}
			self.rowOrders = []
			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Service Report..."

			var postJson = {
				'opType': this.opType,
				'location': this.location,
				'orderType': this.orderType,
				'dateRangeType': this.dateRangeType,
				'fromDate': this.getMidDay(this.fromdate),
				'toDate': this.getMidDay(this.todate),
			}

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetServiceOrders/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'application/json',
					},
				})
				.then(function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
						console.log("getServiceOrders - error:" + response.data.err)
						// Reset loading message
						self.loading = false
						self.loadingMsg = null
					}
					else {
						self.rowOrders = response.data.dataList
						self.colOrders = self.colServiceOrders

						// Reset loading message
						self.loading = false
						self.loadingMsg = null
					}
				})
				.catch(function (error) {
					self.alert = String(error)
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
		},

    // Save Order to database
    async saveRetailOrderToDB (authToken) {
      var self = this

      // Set loading message
      self.loading = true
      self.loadingMsg = 'Saving...'

      if (self.order.altNbr.trim() != ''){
        self.order.total1 = self.order.total
        self.order.total2 = 0
      }
      else {
        self.order.total1 = 0
        self.order.total2 = self.order.total        
      }

      self.order.type = self.orderType
      self.order.updateDate = this.getMidDay(new Date())
      var postJson = self.order

      await axios({
        method: 'POST',
        baseURL: process.env.VUE_APP_ROOT_API,
        url: '/SaveOrder/',
        data: postJson,
        headers: {
          'Authorization': 'Bearer ' + authToken,
		  'OrgId': self.orgId,
          'Content-Type': 'application/json'
        }
      })
        .then(async function (response) {
          if (response.data.err != '') {
            self.$alert(response.data.err, '', 'error', { confirmButtonColor: '#161c2d' })
            console.log('saveOrder - error')
          } else {
			self.$alert('Order Saved.', '', 'success', { confirmButtonColor: '#161c2d' })
          }
          // Reset loading message
          self.loading = false
          self.loadingMsg = null
        })
        .catch(function (error) {
          self.$alert(String(error), '', 'error', { confirmButtonColor: '#161c2d' })
          // Reset loading message
          self.loading = false
          self.loadingMsg = null
        })
    },

	// Update Order Delivery Status - PreDelivery, DeliveryReady, Delivered
	updateOrderStatus(authToken, status) {
		var self = this

		var orders = []
		for (var i=0; i<this.selectedRows.length; i++){
			orders[i] = this.selectedRows[i].nbr
		}

		// Set loading message
		self.loading = true
		self.loadingMsg = "Updating Order Delivery Status..."

		var postJson = {
			'opType': this.opType,
			'orderType': this.orderType,
			'orders': orders,
			'status': status,
		}

		axios({
				method: 'POST',
				baseURL: process.env.VUE_APP_ROOT_API,
				url: '/UpdateOrderStatus/',
				data: postJson,
				headers: {
					'Authorization': 'Bearer ' + authToken,
					'OrgId': self.orgId,
					'Content-Type': 'application/json',
				},
			})
			.then(function (response) {
				if (response.data.err != ''){
					self.alert = response.data.err
					console.log("UpdateOrderStatus - error:" + response.data.err)
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				}
				else {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					// Refresh orders
					self.serverCall('getOrders')
				}
			})
			.catch(function (error) {
				self.alert = String(error)
				// Reset loading message
				self.loading = false
				self.loadingMsg = null
			})
		},

		// Get Receipts - Invoice or Packing List
		async getDeliveryReceipts (authToken, receiptType) {
			var self = this

			var orders = []
			for (var i=0; i<this.selectedRows.length; i++){
				orders[i] = this.selectedRows[i].nbr
			}

			// Set loading message
			self.loading = true
			self.loadingMsg = 'Getting Receipts...'

			var postJson = {
				'orderType': this.orderType,
				'receiptType': receiptType,
				'orders': orders,
			}

			await axios({
				method: 'POST',
				baseURL: process.env.VUE_APP_ROOT_API,
				url: '/GetDeliveryReceipts/',
				data: postJson,
				headers: {
					'Authorization': 'Bearer ' + authToken,
					'OrgId': self.orgId,
					'Content-Type': 'application/json'
					}
			})
			.then(async function (response) {
				if (response.data.err != '') {
					self.alert = response.data.err, '', 'error', { confirmButtonColor: '#161c2d' }
					console.log('getDeliveryReceipts - error')
				} else {
					// var win = window.open("", "Receipt")
					// win.document.body.innerHTML = response.data.receiptHtml
					self.currentPrintType = receiptType
					self.receiptHTMLs = response.data.receiptHtmls
					self.invoicesModal=true
				}
				// Reset loading message
				self.loading = false
				self.loadingMsg = null
			})
			.catch(function (error) {
				self.alert = String(error), '', 'error', { confirmButtonColor: '#161c2d' }
				// Reset loading message
				self.loading = false
				self.loadingMsg = null
			})
		},

		getPaymentSummary(){
			let i = 0
			let total = 0
			let totalTax = 0
			let totalPaid = 0
			let totalOutstanding = 0
			let totalPaidCash = 0
			let totalPaidBank = 0

			while (i < this.rowOrders.length) {
				//Don't include 'Cancelled' orders in 'Summary'
            	if (this.rowOrders[i].cancelled == 'Yes'){
					i++
					continue
				}
                total = total + this.rowOrders[i].total
                totalTax = totalTax + this.rowOrders[i].tax
                //totalPaid = totalPaid +  this.rowOrders[i].paidSoFar
				if (this.rowOrders[i].paidCash)
					totalPaidCash = totalPaidCash + this.rowOrders[i].paidCash
				if (this.rowOrders[i].paidBank)
					totalPaidBank = totalPaidBank + this.rowOrders[i].paidBank

				i++;
			}

			totalPaid = totalPaidCash + totalPaidBank
			totalOutstanding = total - totalPaid
			this.paymentSummary = {'total': Math.round(total), 'paid': Math.round(totalPaid), 'outstanding': Math.round(totalOutstanding),
                   'tax': Math.round(totalTax), 'totalPaidCash': Math.round(totalPaidCash), 'totalPaidBank': Math.round(totalPaidBank)}

			// Show PaymentSummary Modal
			this.paymentSummaryModal = true
		},

		getOutstandingSummary(){
			let i = 0
			let cnt = 0
			let total = 0
			let totalPaid = 0
			let outstanding = 0
			let totalOutstanding = 0
			let totalPaidCash = 0
			let totalPaidBank = 0
			this.outstandingList = []

			while (i < this.rowOrders.length) {
				//Don't include 'Cancelled' orders in 'Summary'
            	if (this.rowOrders[i].cancelled == 'Yes' || this.rowOrders[i].outstanding <= 10){
					i++
					continue
				}
                total = total + this.rowOrders[i].total
                //totalPaid = totalPaid +  this.rowOrders[i].paidSoFar
				if (this.rowOrders[i].paidCash)
					totalPaidCash = totalPaidCash + this.rowOrders[i].paidCash
					outstanding = this.rowOrders[i].total - this.rowOrders[i].paidCash

				if (this.rowOrders[i].paidBank)
					totalPaidBank = totalPaidBank + this.rowOrders[i].paidBank
					outstanding = outstanding - this.rowOrders[i].paidBank

				cnt++
				//this.outstandingList.push({'nbr': cnt, 'name': this.rowOrders[i].partner.name, 'amt': this.rowOrders[i].outstanding})
				this.outstandingList.push({'nbr': cnt, 'name': this.rowOrders[i].partner.name, 'amt': outstanding})

				i++
			}

			//totalOutstanding = total - totalPaid
			totalPaid = totalPaidCash + totalPaidBank
			totalOutstanding = total - totalPaid

			this.outstandingList.push({'nbr': '', 'name': 'Total', 'amt': totalOutstanding})

			// Show Outstandings Modal
			this.outstandingSummaryModal = true
		},


		async printReceipts () {
			await this.$htmlToPaper('receipt')
		},

		downloadItemSummaryReport() {
			if (this.itemSummaryReport==""){
				this.$alert("No data to Report.", '', 'error', { confirmButtonColor: '#161c2d' })
				return
			}
			let blob = new Blob([this.base64toBlob(this.itemSummaryReport, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')], {});
			FileSaver.saveAs(blob, this.itemSummaryReportName + '.xlsx');
		},

		base64toBlob(base64Data, contentType) {
			contentType = contentType || '';
			let sliceSize = 1024;
			let byteCharacters = atob(base64Data);
			let bytesLength = byteCharacters.length;
			let slicesCount = Math.ceil(bytesLength / sliceSize);
			let byteArrays = new Array(slicesCount);
			for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
				let begin = sliceIndex * sliceSize;
				let end = Math.min(begin + sliceSize, bytesLength);

				let bytes = new Array(end - begin);
				for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
					bytes[i] = byteCharacters[offset].charCodeAt(0);
				}
				byteArrays[sliceIndex] = new Uint8Array(bytes);
			}
			return new Blob(byteArrays, { type: contentType });
		},


	},

}
</script>

<style>
	.blue {
		background-color: #F0F8FF;
	}

	.yellow {
		background-color: #90EE90;
	}

	.red {
		background: red;
		color: white;
	}

	.active {
	color : green;
	}
	.custom-th-class {
	text-align: center;	
	}
</style>

